<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 300px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>

      <el-tabs v-if="menuType==='qc'" type="border-card" active-name="first">
        <el-tab-pane key="first" name="first" :label="lendOrder.code||'物品详情'">
          <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="qc"/>
        </el-tab-pane>

        <el-tab-pane v-for="item in busItemList" :key="item.id" :name="item.id" :label="item.lendOrderCode">
          <MaterialInfo :busItem="item" :lendOrder="item.lendOrder" :lend-order-item="item.lendOrderItem" station="qc"/>
        </el-tab-pane>

        <el-tab-pane key="doaReason" name="doaReason" label="DOA故障原因">
          <el-table :data="doaReasonList" border stripe height="262px">
            <el-table-column align="center" prop="assetSn" label="资产码" min-width="220px"></el-table-column>
            <el-table-column align="left" prop="reason" label="DOA故障原因" min-width="500px" header-align="center"></el-table-column>
            <el-table-column align="center" prop="doaDate" label="备案日期" width="150px"></el-table-column>
          </el-table>
        </el-tab-pane>

      </el-tabs>

      <MaterialInfo v-else :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="electric"/>

      <el-form :model="formData" label-width="130px">
        <el-row v-if="'goldenKey' === this.busItem.sortTestType">
          <el-col>
            <el-form-item label="资产码：">
              <vue-qr :size="80" :margin="0" :text="formData.assetSn"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="检验类型：">
              <el-select v-model="busItem.sortTestType" disabled class="form-item">
                <el-option label="外观" value="appearance"></el-option>
                <el-option label="电性能" value="electric"></el-option>
                <el-option label="金钥匙" value="goldenKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="检验提示：">
              <div class="fun-tip">{{ ruleText }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="isKeyboard">
          <el-row>
            <el-col :span="16">
              <el-form-item label="作业方式：">
                <el-select v-model="formData.busItemInfo.keyboardTestType" class="form-item">
                  <el-option v-for="item in keyboardTestTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
                <el-button v-if="formData.busItemInfo.keyboardTestType==='1'" size="mini" style="margin-left: 10px" @click="loadAteLog">加载ATE数据</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="电性能检验结果：">
                <el-select v-model="formData.busItemInfo.test2ResultType" class="form-item" @change="test2ResultTypeChange">
                  <el-option v-for="item in keyboardTest2ResultTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="formData.test2Result===false">
              <el-form-item label="电性能不良代码：">
                <el-select v-model="formData.test2TroubleCode" filterable placeholder="请选择电性能不良代码" class="form-item">
                  <el-option
                      v-for="item in troubleList"
                      :key="item.code"
                      :label="item.code+' '+item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="formData.test2Result===false">
              <el-form-item label="电性能不良原因：">
                <el-input v-model="formData.test2TroubleReason" type="textarea" :rows="1" class="form-item"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="外观等级：">
                <el-select v-model="formData.busItemInfo.appearanceLevel" class="form-item" @change="appearanceLevelChange">
                  <el-option v-for="item in appearanceLevelList" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-if="!isKeyboard">
          <el-row>
            <el-col :span="8">
              <el-form-item label="外观检验结果：">
                <el-radio-group v-model="formData.test1Result" @change="calcResult">
                  <el-radio :label="true">正常</el-radio>
                  <el-radio :label="false">不良</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="外观是否可修复：" v-if="formData.test1Result===false">
                <el-radio-group v-model="formData.test1Repairable">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否可翻新：" v-if="formData.test1Repairable===true&&busItem.planCategory=='011'">
                <el-radio-group v-model="formData.test1Refurbishable">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="外观不良代码：" v-if="formData.test1Result===false">
                <el-select v-model="formData.test1TroubleCode" filterable placeholder="请选择外观不良代码" class="form-item">
                  <el-option
                      v-for="item in troubleList"
                      :key="item.code"
                      :label="item.code+' '+item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="外观不良原因：" v-if="formData.test1Result===false">
                <el-input v-model="formData.test1TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="电性能检验结果：">
                <el-radio-group v-model="formData.test2Result" @change="calcResult">
                  <el-radio :label="true">正常</el-radio>
                  <el-radio :label="false">不良</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电性能不良代码：" v-if="formData.test2Result===false">
                <el-select v-model="formData.test2TroubleCode" filterable placeholder="请选择电性能不良代码" class="form-item">
                  <el-option
                      v-for="item in troubleList"
                      :key="item.code"
                      :label="item.code+' '+item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电性能不良原因：" v-if="formData.test2Result===false">
                <el-input v-model="formData.test2TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="复检方式：">
                <el-select v-model="formData.busItemInfo.qcTestType" filterable class="form-item">
                  <el-option
                      v-for="item in qcTestTypeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="busItem.busItemInfo&&busItem.busItemInfo.ruleColorId=='3010313'">
              <el-form-item label="亮度值：">
                <el-input v-model="formData.busItemInfo.brightness" ref="sortNum" type="number" max="1000" min="0" placeholder="请输入亮度值" class="form-item"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <el-row>
          <el-col :span="8">
            <el-form-item label="检验结果：">
              <el-select v-model="formData.testResult" class="form-item">
                <el-option
                    v-for="item in testResultList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置意见：">
              <el-select v-model="formData.disposeOpinion" class="form-item">
                <el-option
                    v-for="item in disposeOpinionList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验结果描述：">
              <el-input v-model="formData.testResultRemark" type="textarea" :rows="2" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="isKeyboard">
          <el-col :span="8">
            <el-form-item v-if="formData.busItemInfo.keyboardTestType==='1'" label="ATE截图：">
              <ImageViewerComponent v-if="busItem.ateImgFile" v-bind:image-list="[busItem.ateImgFile]"/>
            </el-form-item>
            <el-form-item v-if="formData.busItemInfo.keyboardTestType==='2'" label="ATE截图：">
              <ImageUploadComponent url="upload/guideImage" ref="uploadComponent" :img-cnt="1" @imageChange="setTest2ImgIds"></ImageUploadComponent>
            </el-form-item>
            <el-form-item v-if="formData.busItemInfo.keyboardTestType==='3'" label="电性能检验照片：">
              <PhotographComponent @setImgIds="setTest2ImgIds" ref="imageUpload"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验日志：">
              <el-link v-if="formData.busItemInfo.keyboardTestType==='1'&&busItem.ateLogFile&&busItem.ateLogFile.url" :href="busItem.ateLogFile.url" style="color: goldenrod;line-height: 20px">
                {{ busItem.ateLogFile.fileName }}
              </el-link>
              <div style="width: 400px" v-if="formData.busItemInfo.keyboardTestType!=='1'">
                <DocUploadComponent url="upload/file?type=keyboard" accept=".json" @docChange="setDocIds" ref="fileUpload"/>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isKeyboard">
          <el-col :span="8">
            <el-form-item label="外观检验照片：">
              <PhotographComponent @setImgIds="setTest1ImgIds" ref="test1ImageUpload"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电性能检验照片：">
              <PhotographComponent @setImgIds="setTest2ImgIds" ref="imageUpload"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验日志：">
              <el-link type="primary" :underline="false" href="GoldenKeyExe://">打开金钥匙程序</el-link>
              <div style="width: 400px">
                <DocUploadComponent :data="{assetSn:this.formData.assetSn}" url="upload/test2JsonFile" @docChange="setDocIds" ref="docUpload"/>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item v-if="isTPM" label="TPM检验照片：">
              <PhotographComponent :img-cnt="1" @setImgIds="setTpmImgIds" ref="tpmImageUpload"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联想目标库区：" v-if="lenovoStore&&showLenovoSelect">
              <el-select v-model="formData.storeType" filterable placeholder="请选择联想目标库区" class="form-item">
                <el-option
                    v-for="item in lenovoStoreList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5" style="text-align: right">
            <el-button type="primary" @click="submit" :loading="submitLoading">作业结果提交</el-button>
          </el-col>
          <el-col :span="19">
            <MessageComponent ref="commitMessage"/>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";
import PhotographComponent from "@/components/PhotographComponent";
import DocUploadComponent from "@/components/DocUploadComponent";
import VueQr from 'vue-qr';
import ImageUploadComponent from "@/components/ImageUploadComponent.vue";
import ImageViewerComponent from "@/components/ImageViewerComponent.vue";

export default {
  name: "FunctionTest",
  components: {ImageViewerComponent, ImageUploadComponent, DocUploadComponent, MaterialInfo, MessageComponent, PhotographComponent, VueQr},
  props: {
    menuType: {type: String, required: true}
  },
  data() {
    return {
      formData: {
        assetSn: '',
        test1Result: undefined,
        test1Repairable: '',
        test1Refurbishable: '',
        test1TroubleReason: '',
        test1TroubleCode: '',
        test2Result: undefined,
        test2TroubleCode: '',
        test2TroubleReason: '',
        test2FileId: '',
        test1ImageIds: [],
        test2ImageIds: [],
        tpmImageIds: [],
        testResult: '',
        testResultRemark: '',
        disposeOpinion: '',
        currentPosition: '',
        storeType: '',
        judgeStoreType: '',
        busItemInfo: {
          qcTestType: '',
          keyboardTestType: '1',
          test2ResultType: '',
          appearanceLevel: '',
          brightness: undefined
        }
      },
      canCheck: false,
      busItem: {
        busItemInfo: {}
      },
      busItemList: [],
      doaReasonList: [],
      lendOrder: {},
      lendOrderItem: {},
      troubleList: [],
      ruleText: '',
      loading: false,
      submitLoading: false,
      lenovoStoreList: [],
      showLenovoSelect: false
    }
  },
  computed: {
    disposeOpinionList() {
      return this.$store.getters.getDictList('disposeOpinion') || [];
    },
    testResultList() {
      return this.$store.getters.getDictList('testResult') || [];
    },
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
    qcTestTypeList() {
      return this.$store.getters.getDictList('qcTestType') || [];
    },
    keyboardTestTypeList() {
      return this.$store.getters.getDictList('keyboardTestType') || [];
    },
    test1RepairLocationList() {
      return this.$store.getters.getDictList('test1RepairLocation') || [];
    },
    keyboardTest2ResultTypeList() {
      return this.$store.getters.getDictList('keyboardTest2ResultType') || [];
    },
    lastTest() {
      return this.formData.test2Result === true
    },
    lenovoStore() {
      return this.lastTest && this.busItem.lenovoJudgeStore
    },
    judgeStoreType() {
      if (this.lenovoStore && this.showLenovoSelect) {
        return 'lenovo_more'
      } else if (this.lenovoStore) {
        return 'lenovo_one'
      }
      return 'system_auto'
    },
    isTPM() {
      //主板、电性能(非金钥匙)安全芯片TPM检查  含DT 或dT或YT或yT这4种情况
      if (this.busItem.sortTestType !== 'electric') {
        return false
      }

      let planCategoryArr = ['041', '051', '078', '079'];
      if (!planCategoryArr.includes(this.busItem.planCategory)) {
        return false
      }
      let name = this.busItem.materialName || ''
      if (name.indexOf('DT') != -1 || name.indexOf('dT') != -1 || name.indexOf('YT') != -1 || name.indexOf('yT') != -1) {
        return true;
      }
      return false;
    },
    isKeyboard() {
      return this.busItem.busItemInfo?.keyboardAte
    },
    appearanceLevelList() {
      return this.$store.getters.getDictList('appearanceLevel') || [];
    },
  },
  methods: {
    calcResult() {
      if (this.formData.test1Result && this.formData.test2Result) {
        this.formData.testResult = '1'
        this.formData.disposeOpinion = '20'
      } else {
        this.formData.testResult = undefined
        this.formData.disposeOpinion = undefined
      }
    },
    resetValues() { //不清空资产码、查询信息、提交信息
      this.formData.test1Result = undefined
      this.formData.test1Repairable = ''
      this.formData.test1Refurbishable = ''
      this.formData.test1TroubleReason = ''
      this.formData.test1TroubleCode = ''
      this.formData.test2Result = undefined
      this.formData.test2TroubleCode = ''
      this.formData.test2TroubleReason = ''
      this.formData.test2FileId = ''
      this.formData.test1ImageIds = []
      this.formData.test2ImageIds = []
      this.formData.tpmImageIds = []
      this.formData.testResult = ''
      this.formData.testResultRemark = ''
      this.formData.disposeOpinion = ''
      this.formData.storeType = ''
      this.lenovoStoreList = [];
      this.showLenovoSelect = false;
      this.formData.busItemInfo.qcTestType = '';
      this.formData.busItemInfo.keyboardTestType = '1';
      this.formData.busItemInfo.test2ResultType = '';
      this.formData.busItemInfo.appearanceLevel = '';
      this.formData.busItemInfo.brightness = undefined;

      this.canCheck = false;
      this.busItem = {};
      this.busItemList = [];
      this.doaReasonList = [];
      this.lendOrder = {};
      this.lendOrderItem = {};
      this.troubleList = [];
      this.ruleText = '';
      this.loading = false;
      this.submitLoading = false;
      if (this.$refs.test1ImageUpload) {
        this.$refs.test1ImageUpload.onClear();
      }
      if (this.$refs.imageUpload) {
        this.$refs.imageUpload.onClear();
      }
      if (this.$refs.tpmImageUpload) {
        this.$refs.tpmImageUpload.onClear();
      }
      if (this.$refs.docUpload) {
        this.$refs.docUpload.onClear();
      }
    },
    async submit() {
      this.$refs.commitMessage.clear();
      if (!this.lastTest) {
        this.formData.storeType = '';
        this.lenovoStoreList = [];
        this.showLenovoSelect = false;
      }
      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$refs.commitMessage.showError('未设置工位，请重新登录系统并设置工位信息');
      } else {
        this.formData.currentPosition = currentPosition;
      }
      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }
      if (!this.canCheck) {
        return this.$refs.commitMessage.showError('该资产码不符合' + (this.menuType === 'electric' ? '电性能' : '品质分析') + '检验条件');
      }

      if (this.isKeyboard) {
        let info = this.formData.busItemInfo || {};
        if (!info.test2ResultType) {
          return this.$refs.commitMessage.showError('请选择电性能检验结果');
        }

        if (!info.appearanceLevel) {
          return this.$refs.commitMessage.showError('请选择外观级别');
        }

        //修复后正常的算合格品
        this.formData.test2Result = info.test2ResultType === 'ok' || info.test2ResultType === 'repair_ok'

        if (this.formData.test2Result && info.appearanceLevel === 'A') {
          if (this.formData.testResult !== '1') {
            return this.$refs.commitMessage.showError('外观级别为A时检验结果必须为合格');
          }
          if (this.formData.disposeOpinion !== '20') {
            return this.$refs.commitMessage.showError('外观级别为A时处置意见必须为合格');
          }
        }
      }

      //不良时校验不良字段
      if (!this.isKeyboard && !this.formData.test1Result) {
        if (this.formData.test1Repairable === '') {
          return this.$refs.commitMessage.showError('请选择外观是否可修复');
        }
        if (this.formData.test1Repairable === true && this.busItem.planCategory == '011' && this.formData.test1Refurbishable === '') {
          return this.$refs.commitMessage.showError('请选择是否可翻新');
        }
        if (this.formData.test1TroubleCode === '') {
          return this.$refs.commitMessage.showError('请选择外观不良代码');
        }
      }

      if (this.busItem.busItemInfo && this.busItem.busItemInfo.ruleColorId == '3010313' && this.formData.busItemInfo.brightness === undefined) {
        return this.$refs.commitMessage.showError('请输入亮度值')

      }

      if (this.formData.test2Result !== true && this.formData.test2Result !== false) {
        return this.$refs.commitMessage.showError('请选择电性能检验结果');
      }
      //不良时校验不良字段
      if (!this.formData.test2Result) {
        if (this.formData.test2TroubleCode === '') {
          return this.$refs.commitMessage.showError('请选择电性能不良代码');
        }
      }

      //复检方式
      if (!this.isKeyboard && !this.formData.busItemInfo?.qcTestType) {
        return this.$refs.commitMessage.showError('请选择复检方式');
      }

      if (!this.formData.testResult) {
        return this.$refs.commitMessage.showError('请选择检验结果');
      }

      if (!this.formData.disposeOpinion) {
        return this.$refs.commitMessage.showError('请选择处置意见');
      }
      if (!this.isKeyboard && this.formData.test1Result && this.formData.test2Result) {
        if (this.formData.testResult != '1') {
          return this.$refs.commitMessage.showError('检验结果必须为合格');
        }
        if (this.formData.disposeOpinion != '20') {
          return this.$refs.commitMessage.showError('处置意见必须为合格');
        }
      }

      if (this.isTPM && this.formData.tpmImageIds.length == 0) {
        return this.$refs.commitMessage.showError('TPM检验照片不能为空');
      }

      if (!this.isKeyboard || this.isKeyboard && this.formData.busItemInfo.keyboardTestType === '3') {
        if (this.busItem.sortTestType === 'goldenKey') {
          //金钥匙，合格品必须上传日志文件，不良品可以不上传
          if (!this.formData.test2FileId && this.formData.test2Result) {
            return this.$refs.commitMessage.showError('请上传金钥匙检验日志');
          }
        } else {
          //除维修退回、索赔退回、调拨订单，电性能必须照片
          if (!['50', '60', '120'].includes(this.busItem.materialSource)) {
            if (this.formData.test2ImageIds.length < 1) {
              return this.$refs.commitMessage.showError('请拍电性能检验照片');
            }
          }
        }
      } else {
        if (this.formData.test2ImageIds.length < 1) {
          let msg = this.formData.busItemInfo.keyboardTestType === '1' ? 'ATE截图为空，请先加载数据' : "请上传ATE截图";
          return this.$refs.commitMessage.showError(msg);
        }
        if (!this.formData.test2FileId && this.formData.test2Result) {
          let msg = this.formData.busItemInfo.keyboardTestType === '1' ? 'ATE日志文件为空，请先加载数据' : "请上传ATE日志文件";
          return this.$refs.commitMessage.showError(msg);
        }
      }


      if (!this.formData.storeType && this.lenovoStore) {
        if (this.lenovoStoreList.length == 0) {
          this.submitLoading = true;
          const {data: storeRes} = await this.$axios.post('/busItem/judgeStore', this.formData);
          this.submitLoading = false;
          if (storeRes.code !== 0) {
            return this.$refs.commitMessage.showError(storeRes.message);
          }
          this.lenovoStoreList = storeRes.data || [];
          if (this.lenovoStoreList.length == 0) {
            this.$message.error('联想判保接口没有返回库区，请将条码及检验结果信息给主管，找联想协助检查判库规则')
          }
        }
        if (this.lenovoStoreList.length == 1) {
          this.formData.storeType = this.lenovoStoreList[0].id;
        } else {
          this.showLenovoSelect = true
          return this.$refs.commitMessage.showError("请选择联想目标仓库");
        }
      }

      this.formData.judgeStoreType = this.judgeStoreType
      this.submitLoading = true;
      let url = '/busItem/saveTest2Result'
      if (this.menuType === 'qc') {
        url = '/busItem/saveQcTest2Result'
      }
      const {data: res} = await this.$axios.post(url, this.formData);
      this.submitLoading = false;
      if (res.code !== 0) {
        return this.$refs.commitMessage.showError(res.message);
      }
      let msg = '提交成功，下一工站为：' + res.data.nextStationName;
      this.$refs.commitMessage.showSuccess(msg);
      this.$refs.searchMessage.showSuccess(msg);
      this.resetValues();
      this.formData.assetSn = '';
      this.$refs.assetSn.focus();
    },
    async search() {
      this.resetValues()
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      let url = '/busItem/queryInfoForTest2'
      if (this.menuType === 'qc') {
        url = '/busItem/queryInfoForQcTest2'
      }
      const {data: res} = await this.$axios.get(url, {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.busItemList = res.data.busItemList || [];
      this.doaReasonList = res.data.doaReasonList || [];
      this.busItem.busItemInfo = this.busItem.busItemInfo || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};
      this.formData.test1Result = this.busItem.test1Result;
      this.formData.test1Repairable = this.busItem.test1Repairable
      this.formData.test1Refurbishable = this.busItem.test1Refurbishable
      this.formData.test1TroubleReason = this.busItem.test1TroubleReason
      this.formData.test1TroubleCode = this.busItem.test1TroubleCode
      this.formData.testResult = this.busItem.testResult
      this.formData.testResultRemark = this.busItem.testResultRemark;
      this.formData.disposeOpinion = this.busItem.disposeOpinion
      this.troubleList = res.data.troubleList || [];
      this.ruleText = res.data.ruleText || '';
      let inLineCnt = this.busItem.busItemInfo.inLineCnt || 0;
      if (inLineCnt > 2) {
        this.ruleText = '【第' + inLineCnt + '次来货】' + this.ruleText
      }

      if (res.data.canCheck === false) {
        return this.$refs.searchMessage.showError('该物品需要高级检验人员检验');
      }

      if (!['electric', 'qc'].includes(this.busItem.currentStation)) {
        let msg = '该物品当前工站不是' + (this.menuType === 'electric' ? '电性能检验' : '品质分析') + '工站';
        if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
          msg += '，工站为：' + this.busItem.nextStationName;
        } else if (this.busItem.currentStationName) {
          msg += '，工站为：' + this.busItem.currentStationName;
        }
        return this.$refs.searchMessage.showError(msg);
      } else if (this.busItem.currentStationStatus == 'finish') {
        return this.$refs.searchMessage.showError('当前工站已完成，下一工站为：' + this.busItem.nextStationName);
      }

      this.canCheck = true;
    },

    setTest1ImgIds(idList) {
      this.formData.test1ImageIds = idList || []
    },
    setTest2ImgIds(idList) {
      this.formData.test2ImageIds = idList || []
    },
    setTpmImgIds(idList) {
      this.formData.tpmImageIds = idList || []
    },
    setDocIds(docIds) {
      if (docIds && docIds.length > 0) {
        this.formData.test2FileId = docIds[0];
      } else {
        this.formData.test2FileId = '';
      }
    },
    loadAteLog() {
      if (!this.busItem.id) {
        return this.$message.error('请先输入或扫资产码进行查询');
      }
      if (!this.canCheck) {
        return this.$message.error('该资产码不符合该工站作业条件');
      }

      this.submitLoading = true
      this.$axios.get('keyboardAteLog/getByBusItemId?busItemId=' + this.busItem.id).then(response => {
        this.submitLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        let ateLog = res.data;
        if (!ateLog) {
          return this.$message.error('未找到ATE日志数据，请先在ATE设备推送检验结果日志');
        }

        let logFileId = ateLog.logFile?.id;
        let imgFileId = ateLog.imgFile?.id;
        if (!logFileId) {
          return this.$message.error('未找到ATE日志文件，请先在ATE设备推送检验结果日志');
        }
        if (!imgFileId) {
          return this.$message.error('未找到ATE图片文件，请先在ATE设备推送检验结果日志');
        }

        this.busItem.ateImgFile = ateLog.imgFile
        this.busItem.ateLogFile = ateLog.logFile
        this.formData.test2ImageIds = [imgFileId]
        this.formData.test2FileId = logFileId;
        let info = this.formData.busItemInfo;

        info.test2ResultType = ''

      }, error => {
        this.submitLoading = false;
        return this.$message.error('加载ATE数据失败，' + error.message);
      });

    },
    test2ResultTypeChange() {
      let info = this.formData.busItemInfo;
      this.formData.test2Result = info.test2ResultType === 'ok'

      //根据电性能结果确定检验结果和处理意见
      if (info.test2ResultType === 'ok') {
        this.formData.testResult = "3";
        this.formData.disposeOpinion = "60";
      }
      if (info.test2ResultType === 'ng') {
        this.formData.testResult = "";
        this.formData.disposeOpinion = "";
      }
    },
    appearanceLevelChange() {
      let info = this.formData.busItemInfo;
      if (info.test2ResultType === 'ok') {
        if (info.appearanceLevel === 'A') {
          this.formData.testResult = "1";
          this.formData.disposeOpinion = "20";
        } else {
          this.formData.testResult = "3";
          this.formData.disposeOpinion = "60";
        }
      }
      if (info.test2ResultType === 'ng') {
        this.formData.testResult = "";
        this.formData.disposeOpinion = "";
      }
    }
  }
}
</script>

<style scoped>
.fun-tip {
  font-weight: bold;
  font-size: 16px;
  color: red;
}

.form-item {
  width: 320px;
}

.el-tabs {
  margin-bottom: 20px;
  box-shadow: inherit;
}
</style>
